import { FC } from 'react';
import { ItemFieldType } from '../config';
import { useAppSelector } from '@/store/hooks';
import { selectSupplierHasEquivalentCurrency } from '@/common/components/purchasing/requisition/store/selectors-ts.ts';
import { SUPPLIER_FIELDS } from '@/common/components/purchasing/requisition/categories/items/config';
import { numberToStr } from '@/ts-common/utils/numbers';
import usePurchasingRequisitionItemComparison from '@/common/components/purchasing/requisition/hooks/usePurchasingRequisitionItemComparison.ts';
import TextWithTooltip from '@/common/components/general/TextWithTooltip';

type SupplierFieldComparisonTotalsProps = {
  field: ItemFieldType;
  supplierRequisitionID: string | number;
  categoryID: number;
  isCommonTotals?: boolean;
};

const SupplierFieldComparisonTotals: FC<SupplierFieldComparisonTotalsProps> = ({
  field,
  supplierRequisitionID,
  categoryID,
  isCommonTotals
}) => {
  const supplierHasEquivalentCurrency = useAppSelector(state =>
    selectSupplierHasEquivalentCurrency(state, supplierRequisitionID)
  );

  const itemComparison = usePurchasingRequisitionItemComparison({
    supplierRequisitionID,
    categoryID
  });

  if (
    (!supplierHasEquivalentCurrency && field.key === SUPPLIER_FIELDS.total_discounted_price.key) ||
    (supplierHasEquivalentCurrency &&
      field.key === SUPPLIER_FIELDS.total_discounted_price_base_currency_equivalent.key)
  ) {
    return (
      <TextWithTooltip className="fs-10 fw-normal">
        {numberToStr(itemComparison.data?.totals?.total_common_quoted_price, 2, 2)}
      </TextWithTooltip>
    );
  } else if (
    (!supplierHasEquivalentCurrency && field.key === SUPPLIER_FIELDS.unit_price.key) ||
    (supplierHasEquivalentCurrency &&
      field.key === SUPPLIER_FIELDS.unit_price_base_currency_equivalent.key)
  ) {
    return isCommonTotals ? (
      <TextWithTooltip className="fs-10 fw-normal">
        {numberToStr(itemComparison.data?.totals?.total_common_quoted_items)}/
        {numberToStr(itemComparison.data?.totals?.total_common_items)}
      </TextWithTooltip>
    ) : (
      <TextWithTooltip>
        {numberToStr(itemComparison.data?.totals?.total_quoted_items)}/
        {numberToStr(itemComparison.data?.totals?.total_items)}
      </TextWithTooltip>
    );
  }

  return null;
};

export default SupplierFieldComparisonTotals;
