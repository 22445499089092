import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { SortableHandle } from 'react-sortable-hoc';

import _get from 'lodash/get';

import SvgRender from 'common/components/general/SvgRender';
import FavoriteTableSearch from 'components/layouts/page/table/FavoriteTableSearch';

import { label_path } from 'routing/routes/_paths';
import { stringifyObj } from 'utils/urls';

import dashboard from 'assets/svg/sidebar/dashboard.svg';
import rolodex from 'assets/svg/sidebar/rolodex.svg';
import vessels from 'assets/svg/sidebar/vessels.svg';
import voyages from 'common/assets/svg/common/voyages.svg';
import tools from 'assets/svg/sidebar/tools.svg';
import bin from 'assets/svg/common/bin.svg';
import rearrange from 'common/assets/svg/actions/rearrange.svg';
import estimator from 'assets/svg/sidebar/estimator.svg';
import bunkering_prices from 'assets/svg/sidebar/bunkering_prices.svg';
import captain_reports from 'common/assets/svg/common/captain_reports.svg';
import comparison from 'assets/svg/sidebar/comparison.svg';
import jobs from 'common/assets/svg/common/jobs.svg';
import jobsTriggers from 'common/assets/svg/jobs/jobs_triggers.svg';
import crewPlanning from 'common/assets/svg/common/crew_planning.svg';
import documentManager from 'assets/svg/sidebar/document_manager.svg';
import reminder from 'assets/svg/common/reminder.svg';
import ticketing from 'common/assets/svg/common/airplane-up.svg';
import reports from 'common/assets/svg/common/reports.svg';
import ports from 'assets/svg/sidebar/ports.svg';
import forms from 'common/assets/svg/common/forms.svg';
import events from 'common/assets/svg/common/events.svg';
import mocs from 'common/assets/svg/common/review-process.svg';
import formFields from 'assets/svg/sidebar/digital-form-fields.svg';
import formPlanning from 'common/assets/svg/forms/forms-planning.svg';
import list from 'common/assets/svg/common/list.svg';
import add from 'common/assets/svg/actions/add-action.svg';
import star from 'common/assets/svg/common/star.svg';
import budget from 'common/assets/svg/accounting/budget.svg';
import purchasing from 'common/assets/svg/common/purchasing.svg';
import projects from 'common/assets/svg/common/projects.svg';
import eventTemplate from 'assets/svg/sidebar/event_templates.svg';
import vetting from 'common/assets/svg/common/vetting.svg';
import underwaterServiceTypesIcon from 'assets/svg/sidebar/underwater-service-types.svg';
import findings from 'common/assets/svg/common/findings.svg';
import inventory from 'common/assets/svg/accounting/inventory.svg';
import daCases from '@/common/assets/svg/voyages/da-items.svg';
import invoices from '@/common/assets/svg/common/invoices.svg';

const iconTypes = {
  persons: rolodex,
  companies: rolodex,
  crew: rolodex,
  crew_planning: crewPlanning,
  crew_list: list,
  inventory_spare_parts: inventory,
  tools: tools,
  'market-indexes': tools,
  'bunker-prices': bunkering_prices,
  estimations: estimator,
  dashboard: dashboard,
  vessels,
  voyages,
  captain_reports: captain_reports,
  jobs_list: jobs,
  jobs_flag_extensions_dispensations_list: jobs,
  jobs_orca_jobs_list: jobs,
  pms_jobs_list: jobs,
  pms_jobs_per_system: jobs,
  accounting_budget: budget,
  purchasing_budgeted_quantity: budget,
  jobs_triggers: jobsTriggers,
  comparison: comparison,
  vessel_document_reminders: reminder,
  document_manager_vessels: documentManager,
  document_manager_crew: documentManager,
  document_manager_crew_documents: documentManager,
  ticketing_events: ticketing,
  ticketing_crew: ticketing,
  ports: ports,
  form_submissions: forms,
  form_fields: formFields,
  form_planning: formPlanning,
  crew_evaluation_obligations: star,
  events: events,
  review_process_templates: mocs,
  mga_action_types: add,
  purchasing_requisitions: purchasing,
  purchasing_requisitions_dlv: purchasing,
  purchasing_requisitions_ev: purchasing,
  purchasing_requisitions_approvals: purchasing,
  review_process_list: list,
  projects,
  event_templates: eventTemplate,
  vetting_setup: vetting,
  event_underwater_services_types: underwaterServiceTypesIcon,
  reports_findings: findings,
  port_da_cases: daCases,
  accounting_invoices: invoices
};

const DragHandler = SortableHandle(props => <div>{props.children}</div>);

export const createLink = (item, saved) => {
  if (saved) {
    switch (item.item_type) {
      case 'table':
        const { filters, visible, sorting, page_size, id } = item.item;

        const obj = {};

        if (filters) obj.filters = filters;
        if (visible) obj.visible = visible;
        if (sorting) obj.sorting = sorting;
        if (page_size) obj.page_size = page_size;

        return {
          pathname: _get(label_path, `${[item.type]}.path`, ''),
          search: `?${stringifyObj(obj)}&searchId=${id}`
        };

      case 'dashboard':
        return `${label_path[item.type].path}/${item.item.id}`;
      case 'comparison':
        return {
          pathname: `${_get(label_path, `${[item.type]}.path`, '')}/${item.item.comparison}`,
          search: `?${stringifyObj({ ...item.item.params })}&searchId=${item.item.id}`
        };
      default:
        return '/';
    }
  } else {
    return '/';
  }
};

export const RenderItem = ({ item, saved, togglePanel, onDeleteClick }) => {
  const icon = iconTypes[item.type] || (item.type.startsWith('reports_') ? reports : null);

  const fadeOut = () => {
    const el = document.getElementById(`side-search-sections--item-${item.id}`);
    if (el) {
      el.classList.add('favorite-fade-out');
      setTimeout(() => {
        el.style.opacity = 0;
      }, 1000);
    }
  };

  return (
    <div
      id={`side-search-sections--item-${item.id}`}
      className="side-search-sections--item d-flex align-items-center justify-content-between flex-nowrap ps-2"
    >
      <Link to={createLink(item, saved)} onClick={() => togglePanel(null)}>
        {icon ? <img src={icon} alt="filter icon" width={18} height={18} /> : null}
        <span className="d-inline-flex flex-column ps-2">
          <div className="d-flex align-items-center">
            <span className="fw-normal">{item.title}</span>
            {item.is_owned ? null : <div className="shared-item">Shared</div>}
          </div>
          <span className="text-capitalize fw-normal text-gray-400">
            {_get(label_path, `${[item.type]}.label`, '')}
          </span>
        </span>
      </Link>
      {saved && (
        <div
          className={`side-search-sections--item-actions flex-fill d-flex align-items-center justify-content-end${
            item.favourites_count > 0 ? ' is-favorite' : ''
          }`}
        >
          <FavoriteTableSearch
            id={item.id}
            favouriteType={item.favourite_type ? item.favourite_type : 'item'}
            isFavorite={item.favourites_count > 0}
            className={`p-1 btn-favorite ${
              item.canDelete !== false && !item.is_owned ? 'me-5' : ''
            }`}
            onClick={item.favourite_type ? fadeOut : null}
          />

          {item.canDelete !== false && item.is_owned ? (
            <Button
              type="button"
              color="link"
              className="p-1 d-inline-flex me-1 btn-delete"
              onClick={() => onDeleteClick(item)}
            >
              <SvgRender src={bin} style={{ height: 18, width: 16 }} />
            </Button>
          ) : null}

          {item.canReorder ? (
            <DragHandler>
              <SvgRender
                className="pointer ms-1 me-2"
                src={rearrange}
                style={{ width: 6, height: 10 }}
              />
            </DragHandler>
          ) : null}
        </div>
      )}
    </div>
  );
};

export const renderPagination = (data, fetch) => {
  let bullets = [];

  for (let i = 1; i <= data.paging.last_page; i++) {
    bullets.push(
      <div
        key={i}
        className={i === data.paging.current_page ? 'active' : ''}
        onClick={() =>
          fetch({
            current_page: i,
            per_page: data.paging.per_page,
            types: data.types
          })
        }
      />
    );
  }

  return bullets;
};
