const CommonItemTotalHeader = () => {
  return (
    <div className="position-relative fs-10 fw-bold text-end cps-4 cpe-4">
      <div>ITEMS QUOTED</div>
      <div className="fw-normal cmt-2">COMMON ITEMS QUOTED </div>
    </div>
  );
};

export default CommonItemTotalHeader;
