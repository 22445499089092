import { useAppSelector } from '@/store/hooks';
import {
  selectItemsTableComparisonViewEnabled,
  selectActiveRequisitionID,
  selectItemsTableComparisonBenchmarkThresh,
  selectVisibleSuppliers
} from '@/common/components/purchasing/requisition/store/selectors-ts';
import { selectItemBasicField } from '@/common/components/purchasing/requisition/store/selectors';
import { useComparePurchasingRequisitionItems } from '@/api/purchasing/queries';

const usePurchasingRequisitionItemComparison = ({
  itemID,
  supplierRequisitionID,
  categoryID
}: {
  itemID?: string;
  supplierRequisitionID?: string | number;
  categoryID: number;
}) => {
  const comparisonViewIsEnabled = useAppSelector(selectItemsTableComparisonViewEnabled);
  const requisitionId = useAppSelector(selectActiveRequisitionID);
  const visibleSuppliers = useAppSelector(selectVisibleSuppliers);
  const requisitionItemID = useAppSelector(state =>
    itemID ? selectItemBasicField(state, itemID, 'requisition_item_id') : null
  );
  const benchmarkThresh = useAppSelector(selectItemsTableComparisonBenchmarkThresh);

  const itemComparison = useComparePurchasingRequisitionItems({
    enabled: comparisonViewIsEnabled && !!categoryID && visibleSuppliers.length > 1,
    benchmark_thresh: benchmarkThresh,
    id: requisitionId,
    supplier_ids: visibleSuppliers,
    requisition_item_id: requisitionItemID,
    requisition_supplier_id: supplierRequisitionID,
    category_id: categoryID
  });

  return itemComparison;
};

export default usePurchasingRequisitionItemComparison;
