import { useMemo } from 'react';

import useRouter from 'use-react-router';
import paths from 'routing/routes/_paths';
import {
  selectRequisitionStatusLabel,
  selectVisibleListedItemFields,
  selectVisibleUnlistedItemFields,
  selectCategoryStoreItems,
  selectCategoryPmsItems,
  selectItemSuppliersCount
} from 'common/components/purchasing/requisition/store/selectors';

import { useSelector } from 'react-redux';
import { getCategoryFieldsMaxWidth } from './helpers-ts';
import Item from './Item';

const Items = ({ categoryID, isOutOfList, isPms }) => {
  const itemSuppliersCount = useSelector(selectItemSuppliersCount);
  const status = useSelector(selectRequisitionStatusLabel);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const visibleFields = useSelector(
    isOutOfList ? selectVisibleUnlistedItemFields : selectVisibleListedItemFields
  );
  const allCategoryItems = useSelector(state =>
    isPms ? selectCategoryPmsItems(state, categoryID) : selectCategoryStoreItems(state, categoryID)
  );

  const { location } = useRouter();

  const isInDeliveryReport = useMemo(
    () =>
      location.pathname.includes(paths.purchasing_requisitions) &&
      location.pathname.includes('/suppliers/') &&
      isOnBoard,
    [isOnBoard, location.pathname]
  );

  const categoryFields = useMemo(
    () =>
      visibleFields.filter(field => (!isPms && !field?.isPmsOnly) || (isPms && !field?.isItemOnly)),
    [visibleFields, isPms]
  );

  const calculatedWidth = useMemo(
    () => getCategoryFieldsMaxWidth(categoryFields),
    [categoryFields]
  );

  return allCategoryItems.map((itemId, index) => (
    <Item
      key={itemId}
      id={itemId}
      categoryID={categoryID}
      index={index}
      isOutOfTheList={isOutOfList}
      categoryFields={categoryFields}
      categoryFieldsMinWidth={calculatedWidth}
      hasSuppliers={itemSuppliersCount}
      status={status}
      isInDeliveryReport={isInDeliveryReport}
      isPms={isPms}
    />
  ));
};

export default Items;
