import TextWithTooltip from '@/common/components/general/TextWithTooltip';
import { numberToStr } from '@/ts-common/utils/numbers';
import { FC, useMemo } from 'react';
import { ItemFieldType } from '../config';
import usePurchasingRequisitionItemComparison from '@/common/components/purchasing/requisition/hooks/usePurchasingRequisitionItemComparison.ts';

const characterSize = 5;

type OptimumTotalsColumnProps = {
  field: ItemFieldType;
  categoryID: number;
};

const OptimumTotalsColumn: FC<OptimumTotalsColumnProps> = ({ field, categoryID }) => {
  const itemComparison = usePurchasingRequisitionItemComparison({ categoryID });

  const value = numberToStr(itemComparison.data?.optimum_total_sum, 2, 2);
  const valueLength = value.length || 1;

  const maxCharacters = useMemo(
    () => (field.maxWidth ? Math.round((field.maxWidth - 12) / characterSize) : valueLength),
    [field.maxWidth, valueLength]
  );

  const progress = useMemo(() => (100 * valueLength) / maxCharacters, [maxCharacters, valueLength]);

  return (
    <div className="text-white fw-bold fs-12 pe-1 cps-4">
      <div style={{ fontSize: progress >= 85 ? (progress < 100 ? '86%' : '80%') : '' }}>
        <TextWithTooltip>{value}</TextWithTooltip>
      </div>
    </div>
  );
};

export default OptimumTotalsColumn;
