import { Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useAppSelector } from '@/store/hooks';

import Field from './fields';
import ItemTotals from './components/ItemTotals';

import {
  isItemOutOfTheList,
  selectRequisitionStatusIsDraft
} from 'common/components/purchasing/requisition/store/selectors';
import { selectItemsTableComparisonViewEnabled } from '@/common/components/purchasing/requisition/store/selectors-ts';
import { getInitialValueFrom } from './helpers';
import { getColumnKey } from './helpers-ts';
import {
  FIELDS,
  SUPPLIER_FIELDS
} from '@/common/components/purchasing/requisition/categories/items/config.tsx';
import DiscountFieldHeader from './components/DiscountFieldHeader';
import AutoFillButton from './components/AutoFillButton';
import SupplierHeaderName from './components/SupplierHeaderName';
import LastDeliveredTooltip from './fields/LastDeliveredTooltip';
import SupplierIncludedInPoButton from './components/SupplierIncludedInPoButton';
import AvailabilityHeader from '@/common/components/purchasing/requisition/categories/items/components/AvailabilityHeader';
import CommonItemTotalHeader from './components/CommonItemTotalHeader';
import OptimumTotalsColumn from './components/OptimumTotalsColumn';
import ItemComparisonComments from './components/ItemComparisonComments';
import FieldColumn from './components/FieldColumn';
import CurrencyLabel from './fields/CurrencyLabel';

const Item = ({
  categoryID,
  id,
  index,
  isOutOfTheList,
  hasSuppliers,
  categoryFields,
  categoryFieldsMinWidth,
  status,
  isPms,
  isInDeliveryReport
}) => {
  const comparisonViewIsEnabled = useAppSelector(selectItemsTableComparisonViewEnabled);

  const isOnBoard = useSelector(state => state.isOnBoard);
  const isDraft = useSelector(selectRequisitionStatusIsDraft);

  return (
    <>
      {index === 0 ? (
        <>
          <Row
            className={`purchasing-requisition__items-header ${
              isOutOfTheList ? 'purchasing-requisition__items-header--items-out-of-list' : ''
            } ${
              !hasSuppliers && !isOnBoard
                ? 'purchasing-requisition__items-header--no-suppliers'
                : ''
            } ${comparisonViewIsEnabled ? 'purchasing-requisition__items-header--comparison' : ''}`}
            style={{ minWidth: categoryFieldsMinWidth }}
            noGutters
          >
            {categoryFields.map((field, fieldIndex) => (
              <FieldColumn
                key={getColumnKey(field)}
                field={field}
                isHeader
                isOnBoard={isOnBoard}
                fieldIndex={fieldIndex}
                fields={categoryFields}
                itemID={id}
                categoryID={categoryID}
              >
                <SupplierHeaderName
                  fieldsInfo={{
                    field,
                    fieldIndex,
                    fields: categoryFields
                  }}
                  isDiscount={field.key === SUPPLIER_FIELDS.discount_percentage.key}
                />

                <div
                  className={`purchasing-requisition__items-header-inner ${
                    field.equivalentCurrency ? 'text-moody-blue' : ''
                  }`}
                >
                  {(field.key === FIELDS.requested_packaging_id.key && isPms) ||
                  (field.key === FIELDS.code.key && comparisonViewIsEnabled) ? null : (
                    <>
                      {field.label}{' '}
                      {field.currency ? (
                        <CurrencyLabel
                          supplierRequisitionID={field.supplierRequisitionID}
                          equivalentCurrency={field.equivalentCurrency}
                        />
                      ) : null}
                      {field.key === SUPPLIER_FIELDS.discount_percentage.key ? (
                        <DiscountFieldHeader field={field} categoryID={categoryID} isPms={isPms} />
                      ) : null}
                      {field.key === SUPPLIER_FIELDS.availability.key ? (
                        <AvailabilityHeader field={field} categoryID={categoryID} />
                      ) : null}
                    </>
                  )}
                </div>
              </FieldColumn>
            ))}
          </Row>

          {status && !isDraft ? (
            <Row
              className={`purchasing-requisition__items-footer ${
                comparisonViewIsEnabled ? 'purchasing-requisition__items-footer--expanded' : ''
              }`}
              style={{ minWidth: categoryFieldsMinWidth }}
              noGutters
            >
              {categoryFields.map((field, fieldIndex) => (
                <FieldColumn
                  key={getColumnKey(field)}
                  field={field}
                  isFooter
                  isOnBoard={isOnBoard}
                  fieldIndex={fieldIndex}
                  fields={categoryFields}
                  itemID={id}
                  categoryID={categoryID}
                >
                  {field.key === FIELDS.code.key && comparisonViewIsEnabled ? (
                    <CommonItemTotalHeader />
                  ) : field.key === FIELDS.optimum_totals.key && comparisonViewIsEnabled ? (
                    <OptimumTotalsColumn field={field} categoryID={categoryID} />
                  ) : field.footer?.totals ? (
                    <ItemTotals itemID={id} categoryID={categoryID} field={field} isPms={isPms} />
                  ) : field.hasFillButton ? (
                    <AutoFillButton
                      categoryID={categoryID}
                      field={field}
                      isPms={isPms}
                      isOnBoard={isOnBoard}
                    />
                  ) : null}
                  {field.key === SUPPLIER_FIELDS.proposed_quantity.key &&
                  field.supplierRequisitionID ? (
                    <SupplierIncludedInPoButton
                      isFooter={true}
                      categoryID={categoryID}
                      itemID={id}
                      supplierRequisitionID={field.supplierRequisitionID}
                    />
                  ) : null}
                </FieldColumn>
              ))}
            </Row>
          ) : null}
        </>
      ) : null}
      <Row
        className={`purchasing-requisition__items-single min-h-24 ${
          isOutOfTheList ? 'purchasing-requisition__items-out-of-list' : ''
        }`}
        style={{ minWidth: categoryFieldsMinWidth }}
        noGutters
      >
        {categoryFields.map((field, fieldIndex) => (
          <FieldColumn
            key={getColumnKey(field)}
            field={field}
            isOnBoard={isOnBoard}
            fieldIndex={fieldIndex}
            rowIndex={index}
            fields={categoryFields}
            isInDeliveryReport={isInDeliveryReport}
            data-id={`${categoryID}-${index}-${fieldIndex}`}
            itemID={id}
            categoryID={categoryID}
          >
            {fieldIndex === 0 ? <LastDeliveredTooltip itemID={id} /> : null}
            <Field
              categoryID={categoryID}
              itemID={id}
              field={field}
              initialValueFromField={getInitialValueFrom(field.key)}
              isPms={isPms}
            />
          </FieldColumn>
        ))}
      </Row>

      <ItemComparisonComments
        fields={categoryFields}
        minWidth={categoryFieldsMinWidth}
        itemID={id}
        categoryID={categoryID}
      />
    </>
  );
};

const ItemWrapper = ({ id, isOutOfTheList, ...rest }) => {
  const isItemOutOfList = useSelector(state => isItemOutOfTheList(state, id));

  return (isOutOfTheList && !isItemOutOfList) || (!isOutOfTheList && isItemOutOfList) ? null : (
    <Item id={id} isOutOfTheList={isOutOfTheList} {...rest} />
  );
};

export default ItemWrapper;
