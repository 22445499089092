import { useAppSelector } from '@/store/hooks';
import { selectSupplierCurrency } from 'common/components/purchasing/requisition/store/selectors';
import { selectSystemCurrency } from 'store/settings/selectors';
import { FC } from 'react';

type CurrencyLabelProps = {
  supplierRequisitionID: string;
  equivalentCurrency: boolean;
};

const CurrencyLabel: FC<CurrencyLabelProps> = ({ supplierRequisitionID, equivalentCurrency }) => {
  const supplierCurrency = useAppSelector(state =>
    selectSupplierCurrency(state, supplierRequisitionID)
  );
  const systemCurrency = useAppSelector(selectSystemCurrency);

  return supplierCurrency ? (
    <span className="text-violet">
      {equivalentCurrency ? systemCurrency?.label : supplierCurrency}
    </span>
  ) : (
    ''
  );
};

export default CurrencyLabel;
