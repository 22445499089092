import FieldColumn from './FieldColumn';
import TextWithTooltip from '@/common/components/general/TextWithTooltip';
import styled from '@emotion/styled';
import { FC, useMemo } from 'react';
import { ItemFieldType, FIELDS, SUPPLIER_FIELDS } from '../config';
import { Row } from 'reactstrap';
import { getColumnKey, getCommentMaxWidth } from '../helpers-ts';
import { useAppSelector } from '@/store/hooks';
import {
  selectItemBasicField,
  selectItemsTableComparisonCommentsVisibility
} from '@/common/components/purchasing/requisition/store/selectors-ts.ts';
import { PurchasingRequisitionItemComment } from '@/common/types/purchasing';

type ItemComparisonCommentsProps = {
  fields: ItemFieldType[];
  minWidth: number;
  itemID: string;
  categoryID: number;
};

const ItemComparisonComments: FC<ItemComparisonCommentsProps> = ({
  fields,
  minWidth,
  itemID,
  categoryID
}) => {
  const commentsAreVisible = useAppSelector(selectItemsTableComparisonCommentsVisibility);

  const isOnBoard = useAppSelector(state => state.isOnBoard);

  if (!commentsAreVisible) return null;

  return (
    <Row className={`purchasing-requisition__items-comments g-0`} style={{ minWidth }}>
      {fields.map((field, fieldIndex) => (
        <FieldColumn
          key={getColumnKey(field)}
          field={field}
          isHeader
          isOnBoard={isOnBoard}
          fieldIndex={fieldIndex}
          fields={fields}
          itemID={itemID}
          categoryID={categoryID}
        >
          {[
            FIELDS.code.key,
            SUPPLIER_FIELDS.available_packaging_id.key,
            SUPPLIER_FIELDS.quality_id.key
          ].includes(field.key) ? (
            <Comment itemID={itemID} field={field} fieldIndex={fieldIndex} fields={fields} />
          ) : null}
        </FieldColumn>
      ))}
    </Row>
  );
};

type CommentProps = {
  fields: ItemFieldType[];
  field: ItemFieldType;
  fieldIndex: number;
  itemID: string;
};

const Comment: FC<CommentProps> = ({ itemID, field, fieldIndex, fields }) => {
  const itemComments = useAppSelector(state =>
    selectItemBasicField(state, itemID, FIELDS.comments.key)
  ) as string | PurchasingRequisitionItemComment[];

  const comment = useMemo(() => {
    if (!Array.isArray(itemComments)) return '';

    if (field.supplierRequisitionID) {
      // Supplier Comments
      return itemComments.find(c => c.type === 'supplier' || c.type === 'supplier-excel')?.text;
    }

    // Vessel Comments
    return itemComments.find(c => c.type === 'vessel')?.text;
  }, [field.supplierRequisitionID, itemComments]);

  return (
    <CommentContainer
      width={getCommentMaxWidth(field, fieldIndex, fields)}
      className={`fs-10 fw-normal ${field.key !== FIELDS.code.key ? `ps-1` : ''}`}
    >
      <TextWithTooltip className="h-auto">
        {comment ? <span dangerouslySetInnerHTML={{ __html: comment }} /> : '-'}
      </TextWithTooltip>
    </CommentContainer>
  );
};

const CommentContainer = styled.div`
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  max-width: ${({ width }: { width: number }) => `${width}px`};
`;

export default ItemComparisonComments;
