import _isNumber from 'lodash/isNumber';
import React, { FC, PropsWithChildren } from 'react';
import SupplierincludedInKeyQtnButton from './SupplierIncludedInQtnButton';

import { useAppSelector } from '@/store/hooks';
import { selectItemsTableComparisonViewEnabled } from '@/common/components/purchasing/requisition/store/selectors-ts';
import { getItemClassNames } from '../helpers';
import { Col } from 'reactstrap';
import { ItemFieldType } from '../config';

type FieldColumnProps = {
  field: ItemFieldType;
  isHeader?: boolean;
  isFooter?: boolean;
  isOnBoard?: boolean;
  isCategoryTotals?: boolean;
  fieldIndex: number;
  fields: ItemFieldType[];
  rowIndex?: number;
  isInDeliveryReport?: boolean;
  itemID?: string;
  categoryID?: number;
};

const FieldColumn: FC<PropsWithChildren<FieldColumnProps>> = React.memo(
  ({
    field,
    children,
    isHeader = false,
    isFooter = false,
    isOnBoard,
    isCategoryTotals,
    fieldIndex,
    fields,
    rowIndex,
    isInDeliveryReport,
    itemID,
    categoryID,
    ...rest
  }) => {
    const fieldsInfo = {
      field,
      fieldIndex,
      fields
    };
    const comparisonViewIsEnabled = useAppSelector(selectItemsTableComparisonViewEnabled);
    const fieldsProperties = {
      isHeader,
      isFooter,
      isCategoryTotals,
      isOnBoard,
      isInDeliveryReport,
      comparisonViewIsEnabled
    };

    return (
      <>
        {!isOnBoard &&
        !comparisonViewIsEnabled &&
        field.supplierRequisitionID &&
        field.supplierRequisitionID !== fields[fieldIndex - 1]?.supplierRequisitionID ? (
          <Col
            xs="auto"
            className="requisition-item supplier-qtn d-flex align-items-center justify-content-center py-0 pe-0"
            style={{ maxWidth: 20, minWidth: 20 }}
          >
            {isHeader || !field.supplierRequisitionID || !itemID || !categoryID ? null : (
              <SupplierincludedInKeyQtnButton
                isFooter={isFooter}
                field={field}
                itemID={itemID}
                categoryID={categoryID}
              />
            )}
          </Col>
        ) : null}

        <Col
          xs={field.width}
          className={getItemClassNames(fieldsInfo, fieldsProperties)}
          style={{ maxWidth: field.maxWidth, minWidth: field.maxWidth }}
          data-index={
            (field.key === 'code' || field.key === 'unlisted_description') && _isNumber(rowIndex)
              ? rowIndex + 1
              : undefined
          }
          {...rest}
        >
          {children}
        </Col>
      </>
    );
  }
);
FieldColumn.displayName = 'FieldColumn';

export default FieldColumn;
