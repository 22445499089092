import { AccountType } from '@/common/types/account';
import {
  DeliveryStatus,
  PurchasingRequisitionOnBoardStatus,
  PurchasingRequisitionStatusLabel,
  PurchasingUnit
} from '@/common/types/purchasing';
import permissions from '@/common/utils/permissions/constants';
import { isAuthorized } from '@/utils/permissions/authorize';
import { ItemFieldType } from './config';
import { isSupplierField } from './helpers';
import _sumBy from 'lodash/sumBy';

export const getBasicItemFieldIsLocked = ({
  status,
  onBoardStatus,
  isOnBoard,
  isInitial
}: {
  status?: PurchasingRequisitionStatusLabel;
  onBoardStatus: PurchasingRequisitionOnBoardStatus;
  isOnBoard: boolean;
  isInitial?: boolean;
}) => {
  if (isOnBoard) return onBoardStatus === 'submitted';

  if (onBoardStatus === 'submitted' && status === 'rqn') return isInitial ? true : false;

  return onBoardStatus === 'submitted';
};

export const getRevisedQuantityFieldIsLocked = ({
  account,
  reviewProcessIsApproved
}: {
  account: AccountType;
  reviewProcessIsApproved: boolean;
}) => {
  return (
    !isAuthorized(account, [
      permissions.office.purchasing.requisitions.items.update_revised_quantity
    ]) || reviewProcessIsApproved
  );
};

export const getQuotationFieldIsLocked = ({
  account,
  reviewProcessIsApproved,
  itemID,
  revisedQuantityQuantityIsZero
}: {
  account: AccountType;
  reviewProcessIsApproved: boolean;
  itemID?: string;
  revisedQuantityQuantityIsZero?: boolean;
}) => {
  const isLocked =
    !isAuthorized(account, [permissions.office.purchasing.requisitions.items.edit_quotation]) ||
    reviewProcessIsApproved;

  return itemID ? (revisedQuantityQuantityIsZero ? true : isLocked) : isLocked;
};

export const getProposedQuantityFieldIsLocked = ({
  account,
  reviewProcessIsApproved,
  itemID,
  revisedQuantityQuantityIsZero,
  availableQuantityIsZero
}: {
  account: AccountType;
  reviewProcessIsApproved: boolean;
  itemID?: string;
  revisedQuantityQuantityIsZero?: boolean;
  availableQuantityIsZero?: boolean;
}) => {
  const isLocked =
    !isAuthorized(account, [
      permissions.office.purchasing.requisitions.items.edit_proposed_quantity
    ]) || reviewProcessIsApproved;

  return itemID
    ? revisedQuantityQuantityIsZero || availableQuantityIsZero
      ? true
      : isLocked
    : isLocked;
};

export const getApprovedQuantityFieldIsLocked = ({
  account,
  reviewProcessIsApproved,
  itemID,
  revisedQuantityQuantityIsZero,
  availableQuantityIsZero
}: {
  account: AccountType;
  reviewProcessIsApproved: boolean;
  itemID?: string;
  revisedQuantityQuantityIsZero?: boolean;
  availableQuantityIsZero?: boolean;
}) => {
  if (itemID && (revisedQuantityQuantityIsZero || availableQuantityIsZero)) return true;

  if (reviewProcessIsApproved) {
    return !isAuthorized(account, [
      permissions.office.purchasing.requisitions.items.edit_approved_quantity_for_approved_cases
    ]);
  }

  return !isAuthorized(account, [
    permissions.office.purchasing.requisitions.items.edit_approved_quantity
  ]);
};

export const getDeliveredQuantityFieldIsLocked = ({
  // itemID,
  // supplierDeliveryStatus,
  isForVessel
}: {
  itemID?: string;
  supplierDeliveryStatus: DeliveryStatus;
  isForVessel: boolean;
}) => {
  if (isForVessel) return true; // Delivered Qty is autofilled from Deliberables

  // return itemID ? (supplierDeliveryStatus !== 'submitted' ? false : true) : false;
  return false;
};

export const getColumnKey = (field: ItemFieldType) => {
  return `${field.key}${field.supplierRequisitionID ? `_${field.supplierRequisitionID}` : ''}`;
};

export const getCategoryFieldsMaxWidth = (categoryFields: ItemFieldType[]) => {
  let width = 0;

  categoryFields.forEach(f => (width += f.maxWidth));

  return width;
};

export const getCommentMaxWidth = (
  field: ItemFieldType,
  fieldIndex: number,
  fields: ItemFieldType[]
) => {
  if (field.supplierRequisitionID)
    return (
      _sumBy(
        fields.filter(f => f.supplierRequisitionID === field.supplierRequisitionID),
        f => f.maxWidth
      ) - 16
    );

  const nextSupplierField = fields.findIndex(
    (f, index) => index > fieldIndex && isSupplierField(f)
  );

  return (
    _sumBy(
      fields.filter((_, index) => index >= fieldIndex && index < nextSupplierField),
      f => f.maxWidth
    ) - 48
  );
};

export const getAvailablePurchasingUnitTypeId = (
  purchasingUnits: PurchasingUnit[] | undefined,
  unitId: number
) => {
  if (!purchasingUnits?.length) return;

  const unit = purchasingUnits.find(unit => unit.id === unitId);

  return unit?.type_id;
};
