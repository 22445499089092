import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _debounce from 'lodash/debounce';
import _isNumber from 'lodash/isNumber';

import {
  selectItemBasicField,
  selectOnBoardStatus,
  selectCanEditRequisition,
  isItemOutOfTheList,
  selectItemSupplierDetailsField,
  selectRequisitionIsForVessel,
  selectActiveRequisitionID,
  selectVesselRequisitionIsLockedForOffice,
  selectIsLoadedFromPastRequisition
} from 'common/components/purchasing/requisition/store/selectors';
import { selectListOptionsFromStore } from '@/store/lists/selectors';
import DeleteItem from 'common/components/purchasing/requisition/categories/items/components/DeleteItem';
import {
  setItemField,
  updatePurchasingRequisitionItem,
  setItemFieldError,
  getPurchasingRequisitionSuppliers
} from 'common/components/purchasing/requisition/store/actions';
import permissions from '@/common/utils/permissions/constants';
import { getAvailablePurchasingUnitTypeId } from 'common/components/purchasing/requisition/categories/items/helpers-ts';

import AuthCheck from '@/components/permissions/AuthCheck';
import BasicNumberField from './BasicNumberField';
import HighlightedSupplierField from './HighlightedSupplierField';
import BasicListField from './BasicListField';
import BasicTextField from './BasicTextField';
import CodeDescription from './CodeDescription';
import Comments from './comments';
import File from './File';
import DeliveredQuantityField from './DeliveredQuantityField';
import TotalDeliveredQuantityField from './TotalDeliveredQuantityField';
import LastDelivered from './LastDelivered';
import DifferenceNumberField from './DifferenceNumberField';
import ProposedQuantityField from './ProposedQuantityField';
import OptimumTotalsField from './OptimumTotalsField';
import useFieldIsDisabled from '@/common/components/purchasing/requisition/hooks/useFieldIsDisabled.ts';

const ItemField = React.memo(({ itemID, categoryID, field, initialValueFromField, isPms }) => {
  const { supplierRequisitionID } = field;
  const isForVessel = useSelector(selectRequisitionIsForVessel);
  const initialValueSupplierRequisitionID = initialValueFromField?.key
    ? initialValueFromField.isSupplierField
      ? supplierRequisitionID
      : null
    : supplierRequisitionID;

  const dispatch = useDispatch();

  const isLoadedFromPastRequisition = useSelector(selectIsLoadedFromPastRequisition);

  const value = useSelector(state =>
    supplierRequisitionID
      ? selectItemSupplierDetailsField(state, itemID, field.key, supplierRequisitionID)
      : selectItemBasicField(state, itemID, field.key, supplierRequisitionID)
  );
  const error = useSelector(state =>
    supplierRequisitionID
      ? selectItemSupplierDetailsField(state, itemID, `${field.key}_error`, supplierRequisitionID)
      : selectItemBasicField(state, itemID, `${field.key}_error`, supplierRequisitionID)
  );
  const initialValue = useSelector(state =>
    initialValueSupplierRequisitionID
      ? selectItemSupplierDetailsField(state, itemID, initialValueFromField?.key || field.key)
      : selectItemBasicField(state, itemID, initialValueFromField?.key || field.key)
  );
  const requisitionItemID = useSelector(state =>
    selectItemBasicField(state, itemID, 'requisition_item_id')
  );
  const activeID = useSelector(selectActiveRequisitionID);

  const canEditOutOfTheListItem = useSelector(state => isItemOutOfTheList(state, itemID));
  const isOnBoard = useSelector(state => state.isOnBoard);
  const lockedForOffice = useSelector(selectVesselRequisitionIsLockedForOffice);
  const canEdit = useSelector(selectCanEditRequisition);
  const purchasingUnits = useSelector(state =>
    selectListOptionsFromStore(state, 'purchasing-units')
  );

  const onBoardStatus = useSelector(selectOnBoardStatus);

  const fieldIsDisabled = useFieldIsDisabled(field.key, { itemID, supplierRequisitionID });

  const autoUpdate = useCallback(
    async params => {
      const res = await dispatch(
        updatePurchasingRequisitionItem({
          ...params,
          id: requisitionItemID,
          supplierRequisitionID
        })
      );

      if (_isNumber(params?.delivered_quantity) && !isForVessel && res) {
        await dispatch(getPurchasingRequisitionSuppliers({ id: activeID }));
      }
    },
    [activeID, dispatch, isForVessel, requisitionItemID, supplierRequisitionID]
  );

  const debouncedAutoSave = useMemo(() => _debounce(autoUpdate, 600), [autoUpdate]);
  const autoSave = useCallback(() => dispatch(updatePurchasingRequisitionItem), [dispatch]);

  const onChange = useCallback(
    updated => {
      dispatch(
        setItemField(
          { itemID, supplierRequisitionID: supplierRequisitionID },
          { [field.key]: updated }
        )
      );

      if (updated) {
        dispatch(
          setItemFieldError(
            { itemID, supplierRequisitionID: supplierRequisitionID },
            { [`${field.key}_error`]: null }
          )
        );
      }
    },
    [dispatch, itemID, supplierRequisitionID, field.key]
  );

  const onAutoSave = useCallback(
    (params, debounced = true) =>
      onBoardStatus === 'submitted'
        ? (debounced ? debouncedAutoSave : autoSave)({
            ...params,
            id: requisitionItemID,
            supplierRequisitionID
          })
        : null,
    [autoSave, debouncedAutoSave, onBoardStatus, requisitionItemID, supplierRequisitionID]
  );

  let InputComponent = null;
  const inputProps = {
    categoryID,
    itemID,
    error,
    onChange,
    disabled: fieldIsDisabled, // Update useFieldIsDisabled to set the locking logic per field
    fieldKey: field.key,
    fieldMaxWidth: field.maxWidth,
    fieldShowInComparisonView: field.showInComparisonView,
    supplierRequisitionID: supplierRequisitionID
  };
  const inputValue = value || value === 0 ? value : initialValue;

  switch (field.key) {
    case 'code':
      InputComponent = CodeDescription;
      break;
    case 'rob':
      InputComponent = BasicNumberField;
      inputProps.decimalScale = 0;
      inputProps.onAutoSave = onAutoSave;
      break;
    case 'requested_quantity':
      InputComponent = BasicNumberField;
      inputProps.decimalScale = 0;
      inputProps.onAutoSave = onAutoSave;
      break;
    case 'last_delivered_quantity':
      InputComponent = LastDelivered;
      break;
    case 'requested_packaging_id':
      if (isPms) return null;

      inputProps.onAutoSave = onAutoSave;

      return (
        <BasicListField
          list="purchasing-units"
          value={inputValue}
          hidden={canEditOutOfTheListItem}
          {...inputProps}
        />
      );
    case 'revised_quantity': {
      InputComponent = DifferenceNumberField;
      inputProps.fieldKeyToCompare = 'requested_quantity';
      inputProps.decimalScale = 0;
      inputProps.onAutoSave = onAutoSave;
      inputProps.hidden = !isOnBoard && canEditOutOfTheListItem;

      break;
    }
    case 'unlisted_description':
      InputComponent = BasicTextField;
      break;
    case 'comments':
      inputProps.requisitionItemID = requisitionItemID;
      inputProps.lockedForOffice = lockedForOffice;
      inputProps.isLoadedFromPastRequisition = isLoadedFromPastRequisition;
      InputComponent = Comments;
      break;
    case 'file':
      InputComponent = File;
      break;
    case 'delete':
      if (isOnBoard) {
        return onBoardStatus === 'draft' ? (
          <DeleteItem itemID={itemID} categoryID={categoryID} />
        ) : null;
      }

      return canEdit || canEditOutOfTheListItem ? (
        <AuthCheck
          permissions={activeID ? [permissions.office.purchasing.requisitions.items.delete] : []}
        >
          <DeleteItem itemID={itemID} categoryID={categoryID} />
        </AuthCheck>
      ) : null;
    case 'available_packaging_id': {
      inputProps.onAutoSave = onAutoSave;
      inputProps.isClearable = true;

      // Find the type_id of the requested packing by using its id, inside the purchasing units list
      const typeId = getAvailablePurchasingUnitTypeId(purchasingUnits, inputValue);

      if (typeId) {
        // Use the type_id to filter the available packing options
        inputProps.filterOption = option => option.data.type_id === typeId;
      }

      return <BasicListField list="purchasing-units" value={inputValue} {...inputProps} />;
    }
    case 'quality_id':
      inputProps.onAutoSave = onAutoSave;
      inputProps.isClearable = true;

      return <BasicListField list="spare-parts-quality" value={inputValue} {...inputProps} />;
    case 'available_quantity':
      InputComponent = DifferenceNumberField;
      inputProps.fieldKeyToCompare = 'revised_quantity';
      inputProps.decimalScale = 0;
      inputProps.onAutoSave = onAutoSave;

      break;
    case 'unit_price':
      InputComponent = BasicNumberField;
      inputProps.decimalScale = 2;
      inputProps.fixedDecimalScale = 2;
      inputProps.onAutoSave = onAutoSave;

      break;
    case 'unit_price_base_currency_equivalent':
      InputComponent = BasicNumberField;
      inputProps.decimalScale = 2;
      inputProps.fixedDecimalScale = 2;
      inputProps.onAutoSave = onAutoSave;

      break;
    case 'discount_percentage':
      InputComponent = BasicNumberField;
      inputProps.decimalScale = 2;
      inputProps.onAutoSave = onAutoSave;

      break;
    case 'total_original_price':
    case 'total_original_price_base_currency_equivalent':
      InputComponent = BasicNumberField;
      inputProps.decimalScale = 2;
      inputProps.fixedDecimalScale = 2;
      break;
    case 'total_discounted_price':
      InputComponent = HighlightedSupplierField;
      inputProps.decimalScale = 2;
      inputProps.fixedDecimalScale = 2;
      inputProps.comparisonFieldKey = 'total_discounted_price_base_currency_equivalent';

      break;
    case 'total_discounted_price_base_currency_equivalent':
      InputComponent = HighlightedSupplierField;
      inputProps.decimalScale = 2;
      inputProps.fixedDecimalScale = 2;
      inputProps.comparisonFieldKey = 'total_discounted_price_base_currency_equivalent';

      break;
    case 'total_proposed_price':
    case 'total_proposed_price_base_currency_equivalent':
      InputComponent = BasicNumberField;
      inputProps.decimalScale = 2;
      inputProps.fixedDecimalScale = 2;

      break;
    case 'total_approved_price':
    case 'total_approved_price_base_currency_equivalent':
      InputComponent = BasicNumberField;
      inputProps.decimalScale = 2;
      inputProps.fixedDecimalScale = 2;

      break;
    case 'total_delivered_price':
    case 'total_delivered_price_base_currency_equivalent':
      InputComponent = BasicNumberField;
      inputProps.decimalScale = 2;
      inputProps.fixedDecimalScale = 2;

      break;
    case 'proposed_quantity':
      InputComponent = ProposedQuantityField;
      inputProps.categoryID = categoryID;
      inputProps.fieldKeyToCompare = 'available_quantity';
      inputProps.decimalScale = 0;
      inputProps.onAutoSave = onAutoSave;

      break;
    case 'approved_quantity':
      InputComponent = DifferenceNumberField;
      inputProps.fieldKeyToCompare = 'proposed_quantity';
      inputProps.decimalScale = 0;
      inputProps.onAutoSave = onAutoSave;

      break;

    case 'total_proposed_quantity':
    case 'total_approved_quantity':
      InputComponent = BasicNumberField;
      inputProps.decimalScale = 0;

      break;

    case 'delivered_quantity':
      return (
        <DeliveredQuantityField
          fieldKeyToCompare="approved_quantity"
          onAutoSave={onAutoSave}
          isOnBoard={isOnBoard}
          itemID={itemID}
          supplierRequisitionID={supplierRequisitionID}
          decimalScale={0}
          value={inputValue}
          {...inputProps}
        />
      );

    case 'total_delivered_quantity':
      return <TotalDeliveredQuantityField itemID={itemID} />;

    case 'availability':
      InputComponent = BasicNumberField;
      inputProps.decimalScale = 0;
      inputProps.onAutoSave = onAutoSave;

      break;

    case 'optimum_totals':
      return <OptimumTotalsField itemID={itemID} field={field} categoryID={categoryID} />;

    default:
      return '-';
  }

  return <InputComponent value={inputValue} {...inputProps} disabled={fieldIsDisabled} />;
});

ItemField.displayName = 'ItemField';

export default ItemField;
