import NumberInput from 'common/components/form/inputs/NumberInput';
import SupplierFieldComparisonDetails from '../components/SupplierFieldComparisonDetails';
import { numberToStr, strToNumber } from '@/ts-common/utils/numbers';
import { FC, useMemo } from 'react';
import { NumericFormatProps } from 'react-number-format';

export interface BasicNumberFieldProps extends Omit<NumericFormatProps, 'fixedDecimalScale'> {
  onAutoSave?: (params: { [key: string]: number | null }) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement> | string) => void;
  fieldKey: string;
  includeSpacing?: boolean;
  fieldMaxWidth: number;
  fieldShowInComparisonView?: boolean;
  fixedDecimalScale?: number;
  decimalScale?: number;
  supplierRequisitionID?: string;
  itemID?: string;
  categoryID?: number;
  viewOnly?: boolean;
  disabled?: boolean;
  innerClassName?: string;
}

const characterSize = 5;

const BasicNumberField: FC<BasicNumberFieldProps> = ({
  value,
  onChange,
  onAutoSave,
  disabled,
  fieldKey,
  fieldMaxWidth,
  fieldShowInComparisonView,
  className,
  decimalScale,
  fixedDecimalScale,
  ...rest
}) => {
  const maxCharacters = useMemo(
    () => Math.round((fieldMaxWidth - 12) / characterSize),
    [fieldMaxWidth]
  );

  const valueLength = useMemo(() => {
    const number = numberToStr(value, decimalScale, fixedDecimalScale);

    return number.length || 1;
  }, [decimalScale, fixedDecimalScale, value]);

  const progress = useMemo(() => (100 * valueLength) / maxCharacters, [maxCharacters, valueLength]);

  return (
    <>
      <NumberInput
        disabled={disabled}
        className={`purchasing-number-input mb-0 white-bg ${
          disabled && !className?.length ? 'cpe-4' : ''
        } ${className ? className : ''}`}
        innerClassName="text-end"
        value={value || value === 0 ? value : ''}
        allowNegative={false}
        invisible={!!disabled}
        onChange={e => {
          if (onChange) onChange(e.target.value);
          if (onAutoSave) onAutoSave({ [fieldKey]: strToNumber(e.target.value) });
        }}
        decimalScale={decimalScale}
        fixedDecimalScale={!!fixedDecimalScale}
        style={{
          fontSize: progress >= 80 ? (progress <= 100 ? '80%' : '72%') : ''
        }}
        {...rest}
      />
      {fieldShowInComparisonView && rest.supplierRequisitionID && rest.itemID && rest.categoryID ? (
        <SupplierFieldComparisonDetails
          fieldKey={fieldKey}
          supplierRequisitionID={rest.supplierRequisitionID}
          itemID={rest.itemID}
          value={value}
          categoryID={rest.categoryID}
        />
      ) : null}
    </>
  );
};

export default BasicNumberField;
