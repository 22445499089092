import {
  selectColumnTotals,
  selectItemsTableComparisonViewEnabled
} from '@/common/components/purchasing/requisition/store/selectors-ts.ts';
import { useAppSelector } from '@/store/hooks';
import { numberToStr } from 'common/utils/numbers';
import { FC, useMemo } from 'react';
import { ItemFieldType } from '@/common/components/purchasing/requisition/categories/items/config';
import { SUPPLIER_FIELDS } from '@/common/components/purchasing/requisition/categories/items/config.tsx';
import TextWithTooltip from '@/common/components/general/TextWithTooltip';
import SupplierFieldComparisonTotals from './SupplierFieldComparisonTotals';

const characterSize = 5;

type ItemTotalsProps = {
  field: ItemFieldType;
  categoryID: number;
  isPms: boolean;
};

const ItemTotals: FC<ItemTotalsProps> = ({ field, categoryID, isPms }) => {
  const { supplierRequisitionID } = field;
  const totals = useAppSelector(state =>
    numberToStr(
      selectColumnTotals(state, categoryID, field.key, supplierRequisitionID, isPms),
      2,
      2
    )
  );
  const comparisonViewIsEnabled = useAppSelector(selectItemsTableComparisonViewEnabled);

  const valueLength = useMemo(() => {
    const number = numberToStr(totals, 2, 2);

    return number?.length || 1;
  }, [totals]);

  const maxCharacters = useMemo(
    () => (field.maxWidth ? Math.round((field.maxWidth - 12) / characterSize) : valueLength),
    [field.maxWidth, valueLength]
  );

  const progress = useMemo(() => (100 * valueLength) / maxCharacters, [maxCharacters, valueLength]);

  return (
    <div
      className={`position-relative text-end overflow-hidden fw-bold pe-1 ${
        comparisonViewIsEnabled ? 'd-flex flex-column h-100p' : ''
      }`}
      style={{ fontSize: progress >= 85 ? (progress < 100 ? '86%' : '80%') : '' }}
    >
      {[
        SUPPLIER_FIELDS.unit_price.key,
        SUPPLIER_FIELDS.unit_price_base_currency_equivalent.key
      ].includes(field.key) ? (
        comparisonViewIsEnabled && supplierRequisitionID ? (
          <SupplierFieldComparisonTotals
            field={field}
            supplierRequisitionID={supplierRequisitionID}
            categoryID={categoryID}
          />
        ) : null
      ) : (
        <TextWithTooltip>{totals}</TextWithTooltip>
      )}
      {comparisonViewIsEnabled && supplierRequisitionID && (
        <SupplierFieldComparisonTotals
          field={field}
          supplierRequisitionID={supplierRequisitionID}
          categoryID={categoryID}
          isCommonTotals={true}
        />
      )}
    </div>
  );
};

export default ItemTotals;
