import { FC } from 'react';
import { ItemFieldType } from '../config';
import usePurchasingRequisitionItemComparison from '@/common/components/purchasing/requisition/hooks/usePurchasingRequisitionItemComparison.ts';
import BasicNumberField from './BasicNumberField';

type OptimumTotalsFieldProps = {
  itemID: string;
  field: ItemFieldType;
  categoryID: number;
};

const OptimumTotalsField: FC<OptimumTotalsFieldProps> = ({ itemID, field, categoryID }) => {
  const itemComparison = usePurchasingRequisitionItemComparison({
    itemID,
    categoryID
  });

  return (
    <BasicNumberField
      value={itemComparison.data?.item_optimum_totals?.lowest_total_price}
      disabled={true}
      decimalScale={2}
      fixedDecimalScale={2}
      fieldKey={field.key}
      fieldMaxWidth={field.maxWidth}
      itemID={itemID}
      categoryID={categoryID}
    />
  );
};

export default OptimumTotalsField;
