import { useCallback } from 'react';
import SupplierFieldComparisonDetails from '../components/SupplierFieldComparisonDetails';
import Select from '@/ts-common/components/form/inputs/select';

const BasicListField = ({
  value,
  onChange,
  onAutoSave,
  disabled,
  list,
  fieldKey,
  fieldShowInComparisonView,
  hidden,
  ...rest
}) => {
  const handleOnChange = useCallback(
    v => {
      onChange(v ? v.id : null);
      if (onAutoSave) onAutoSave({ [fieldKey]: v ? v.id : null });
    },
    [fieldKey, onAutoSave, onChange]
  );

  return (
    <>
      <Select
        className={`mb-0 ${hidden ? 'opacity-0' : ''}`}
        value={value}
        isDisabled={disabled || hidden}
        onChange={handleOnChange}
        isAsync={false}
        memoizedRequestParams={{ path: '/lists', params: { list } }}
        getOptionLabel={option => option.name}
        placeholder=""
        invisible={disabled}
        {...rest}
      />
      {fieldShowInComparisonView ? (
        <SupplierFieldComparisonDetails
          fieldKey={fieldKey}
          supplierRequisitionID={rest.supplierRequisitionID}
          itemID={rest.itemID}
          categoryID={rest.categoryID}
        />
      ) : null}
    </>
  );
};

export default BasicListField;
